import React from "react";
import ProjectDetails from "../components/ProjectDetails";
import ProjectNav from "../components/ProjectNav";
function Projects() {
  return (
    <div>
      <ProjectNav />
      <ProjectDetails />
    </div>
  );
}

export default Projects;
