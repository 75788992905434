import React, { useContext } from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import { useLocation, Link } from "react-router-dom"; // Import Link from react-router-dom
import { Data } from "../Data"; // Importing the Data context to access Firestore data

function ProjectNav() {
  const location = useLocation(); // Get the current location
  const { projects, loading } = useContext(Data); // Access projects and loading state from the context

  if (loading) {
    return <div style={{ margin: "auto" }}>Loading Tabs...</div>; // Display a loading message until data is fetched
  }

  // Sort projects based on priority (higher priority comes first)
  const sortedProjects = [...projects].sort((a, b) => b.priority - a.priority);

  return (
    <div className="project-nav">
      <Nav fill className="project-nav-bar">
        {sortedProjects.map((project, index) => (
          <NavItem key={index} className="project-nav-item">
            <NavLink
              tag={Link} // Use the Link component from react-router-dom
              to={`/projects/${index}`}
              className={location.pathname === `/projects/${index}` ? "project-nav-link active" : "project-nav-link"} // Compare current location to dynamically set active state
            >
              {project.name} {/* Display the project name */}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
    </div>
  );
}

export default ProjectNav;
