import React from "react";

// Helper function to format dates as "Month Year"
const formatDate = (dateString) => {
  if (!dateString) return ""; // Handle empty date strings

  const date = new Date(dateString);
  const options = { year: "numeric", month: "long" };
  return date.toLocaleDateString("en-US", options); // e.g., "August 2024"
};

function EducationCard({
  degree,
  department,
  startDate,
  endDate,
  university,
  location,
  description,
}) {
  // Check if description is non-empty and not just <p><br/></p>
  const hasValidDescription =
    description && description.trim() !== "" && description !== "<p><br></p>";

  return (
    <div className="education-card">
      <h4>{degree}</h4>
      <p>{department}</p>
      <p>{formatDate(startDate)} - {formatDate(endDate)}</p>
      <p>{university}, {location}</p>
      
      {/* Render description if it is valid */}
      {hasValidDescription && (
        <div
          className="education-description"
          dangerouslySetInnerHTML={{ __html: description }}
        ></div>
      )}
    </div>
  );
}

export default EducationCard;
