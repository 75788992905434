import React, { useState, useEffect, createContext } from 'react';
import { db } from './firebase'; // Firestore instance
import { getDocs, collection, doc, getDoc } from 'firebase/firestore';
import {
  PROJECTS, ABOUT, EDUCATION, EXPERIENCE, LANGUAGES, SKILLS, CERTIFICATIONS, AWARDS, ACHIEVEMENTS,
  VOLUNTEER_WORK, PUBLICATIONS, STATIC_CONTENT, FIREBASE_COLLECTION, LINKS
} from './constants';

// Create a context for the data
export const Data = createContext();

export const DataProvider = ({ children }) => {
  const [about, setAbout] = useState(ABOUT);
  const [education, setEducation] = useState(EDUCATION.filter(item => item.isOn));
  const [experience, setExperience] = useState(EXPERIENCE.filter(item => item.isOn));
  const [languages, setLanguages] = useState(LANGUAGES.filter(item => item.isOn));
  const [skills, setSkills] = useState(SKILLS.filter(item => item.isOn));
  const [certifications, setCertifications] = useState(CERTIFICATIONS.filter(item => item.isOn));
  const [awards, setAwards] = useState(AWARDS.filter(item => item.isOn));
  const [achievements, setAchievements] = useState(ACHIEVEMENTS.filter(item => item.isOn));
  const [volunteerWork, setVolunteerWork] = useState(VOLUNTEER_WORK.filter(item => item.isOn));
  const [publications, setPublications] = useState(PUBLICATIONS.filter(item => item.isOn));
  const [links, setLinks] = useState(LINKS.filter(item => item.isOn));
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);

  const isSessionStorageAvailable = () => {
    try {
      sessionStorage.setItem('test', 'test');
      sessionStorage.removeItem('test');
      return true;
    } catch (e) {
      return false;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const useSessionStorage = isSessionStorageAvailable();

        const storedProjects = useSessionStorage ? sessionStorage.getItem('projects') : null;
        const storedAbout = useSessionStorage ? sessionStorage.getItem('about') : null;
        const storedEducation = useSessionStorage ? sessionStorage.getItem('education') : null;
        const storedExperience = useSessionStorage ? sessionStorage.getItem('experience') : null;
        const storedLanguages = useSessionStorage ? sessionStorage.getItem('languages') : null;
        const storedSkills = useSessionStorage ? sessionStorage.getItem('skills') : null;
        const storedCertifications = useSessionStorage ? sessionStorage.getItem('certifications') : null;
        const storedAwards = useSessionStorage ? sessionStorage.getItem('awards') : null;
        const storedAchievements = useSessionStorage ? sessionStorage.getItem('achievements') : null;
        const storedVolunteerWork = useSessionStorage ? sessionStorage.getItem('volunteerWork') : null;
        const storedPublications = useSessionStorage ? sessionStorage.getItem('publications') : null;
        const storedLinks = useSessionStorage ? sessionStorage.getItem('links') : null;

        if (
          storedProjects && storedAbout && storedEducation &&
          storedExperience && storedLanguages && storedSkills &&
          storedCertifications && storedAwards && storedAchievements &&
          storedVolunteerWork && storedPublications && storedLinks
        ) {
          setProjects(JSON.parse(storedProjects).filter(project => project.isOn));
          setAbout(JSON.parse(storedAbout));
          setEducation(JSON.parse(storedEducation).filter(item => item.isOn));
          setExperience(JSON.parse(storedExperience).filter(item => item.isOn));
          setLanguages(JSON.parse(storedLanguages).filter(item => item.isOn));
          setSkills(JSON.parse(storedSkills).filter(item => item.isOn));
          setCertifications(JSON.parse(storedCertifications).filter(item => item.isOn));
          setAwards(JSON.parse(storedAwards).filter(item => item.isOn));
          setAchievements(JSON.parse(storedAchievements).filter(item => item.isOn));
          setVolunteerWork(JSON.parse(storedVolunteerWork).filter(item => item.isOn));
          setPublications(JSON.parse(storedPublications).filter(item => item.isOn));
          setLinks(JSON.parse(storedLinks).filter(item => item.isOn));
          setLoading(false);
        } else {
          const fetchDataFromFirestore = async (collectionName, setData, staticData) => {
            try {
              const collectionRef = collection(db, collectionName);
              const snapshot = await getDocs(collectionRef);
              const data = snapshot.docs.map(doc => doc.data()).filter(item => item.isOn);
              setData(data);
              if (useSessionStorage) sessionStorage.setItem(collectionName, JSON.stringify(data));
            } catch (error) {
              console.warn(`Failed to fetch ${collectionName} from Firestore, using static data.`);
              setData(staticData);
            }
          };

          if (STATIC_CONTENT.projects) {
            setProjects(PROJECTS.filter(project => project.isOn));
          } else {
            try {
              const projectsCollection = collection(db, FIREBASE_COLLECTION.collection);
              const projectsSnapshot = await getDocs(projectsCollection);
              const projectsData = projectsSnapshot.docs.map(doc => doc.data()).filter(project => project.isOn);
              setProjects(projectsData);
              if (useSessionStorage) sessionStorage.setItem('projects', JSON.stringify(projectsData));
            } catch (error) {
              console.warn("Failed to fetch 'projects' data from Firestore, using static data.");
              setProjects(PROJECTS.filter(project => project.isOn));
            }
          }

          if (!STATIC_CONTENT.about) {
            try {
              const aboutDocRef = doc(db, 'about', 'personalInfo');
              const aboutDoc = await getDoc(aboutDocRef);
              if (aboutDoc.exists()) {
                const aboutData = aboutDoc.data();
                setAbout(aboutData);
                if (useSessionStorage) sessionStorage.setItem('about', JSON.stringify(aboutData));
              } else {
                throw new Error("Document 'personalInfo' not found");
              }
            } catch (error) {
              console.warn("Failed to fetch 'about' data from Firestore, using static data.");
              setAbout(ABOUT);
            }

            await fetchDataFromFirestore('education', setEducation, EDUCATION);
            await fetchDataFromFirestore('experience', setExperience, EXPERIENCE);
            await fetchDataFromFirestore('languages', setLanguages, LANGUAGES);
            await fetchDataFromFirestore('skills', setSkills, SKILLS);
            await fetchDataFromFirestore('certifications', setCertifications, CERTIFICATIONS);
            await fetchDataFromFirestore('awards', setAwards, AWARDS);
            await fetchDataFromFirestore('achievements', setAchievements, ACHIEVEMENTS);
            await fetchDataFromFirestore('volunteerWork', setVolunteerWork, VOLUNTEER_WORK);
            await fetchDataFromFirestore('publications', setPublications, PUBLICATIONS);
            await fetchDataFromFirestore('links', setLinks, LINKS);
          }

          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <Data.Provider value={{
      projects, about, education, experience, languages, skills,
      certifications, awards, achievements, volunteerWork, publications, links, loading
    }}>
      {children}
    </Data.Provider>
  );
};
