import React, { useState } from 'react';
import { Carousel, CarouselItem, CarouselControl, CarouselIndicators } from 'reactstrap';

function ProjectCarousel({ mediaUrls = [], generateAltText }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  if (mediaUrls.length === 0) return null;

  const hasVideo = mediaUrls.some(media => media.type === 'video' || media.type === 'youtube');
  const interval = hasVideo ? false : 3000;

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === mediaUrls.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? mediaUrls.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = mediaUrls.map((media, index) => (
    <CarouselItem
      className='video-container'
      onExiting={() => setAnimating(true)}
      onExited={() => setAnimating(false)}
      key={media.url}
    >
      {media.type === 'youtube' ? (
        <iframe
          width="100%"
          src={media.url}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title={`Video ${index + 1}`}
        ></iframe>
      ) : media.type === 'video' ? (
        <video controls width="100%">
          <source src={media.url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ) : (
        <img src={media.url} alt={generateAltText(index)} className="carousel-image" />
      )}
    </CarouselItem>
  ));

  return (
    <Carousel
      className={hasVideo ? 'video-carousel' : ''}
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      interval={interval}
    >
      <CarouselIndicators items={mediaUrls} activeIndex={activeIndex} onClickHandler={goToIndex} />
      {slides}
      <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
      <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
    </Carousel>
  );
}

export default ProjectCarousel;
