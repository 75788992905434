import React from "react";

function Skills({ skills }) {
  const hardSkills = skills
    .filter(skill => !skill.isSoft)
    .sort((a, b) => b.experience - a.experience)
    .map(skill => skill.skillName);

  const softSkills = skills
    .filter(skill => skill.isSoft)
    .sort((a, b) => b.experience - a.experience)
    .map(skill => skill.skillName);

  return (hardSkills.length > 0 || softSkills.length > 0) ? (
    <>
      {/* <h3>Skills</h3> */}
      <div className="skills-section">
        <div className="skills-column">
          <h3>Hard Skills</h3>
          <div className="skills-container">
            {hardSkills.map((skill, index) => (
              <span key={index} className="skill-badge">{skill}</span>
            ))}
          </div>
        </div>
        <div className="skills-column">
          <h3>Soft Skills</h3>
          <div className="skills-container">
            {softSkills.map((skill, index) => (
              <span key={index} className="skill-badge">{skill}</span>
            ))}
          </div>
        </div>
      </div>
    </>
  ) : null;
}

export default Skills;
