import React from "react";

function VolunteerWork({ volunteerWork }) {
  return volunteerWork?.length > 0 ? (
    <>
      <h3>Volunteer Work</h3>
      <ul>
        {volunteerWork.map((work, index) => (
          <li key={index}>
            <strong>{work.role}</strong> at{" "}
            {work.organizationURL ? (
              <a href={work.organizationURL} target="_blank" rel="noopener noreferrer">
                {work.organization}
              </a>
            ) : (
              work.organization
            )},{" "}
            {work.date && new Date(work.date).toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
            })}
            {work.location && <span> in {work.location}</span>}
            {work.description && (
              <div className="volunteer-description" dangerouslySetInnerHTML={{ __html: work.description }}></div>
            )}
          </li>
        ))}
      </ul>
    </>
  ) : null;
}

export default VolunteerWork;
