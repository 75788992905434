import React, { useContext } from 'react';
import { Data } from '../Data'; // Import the Data context

function Title() {
  const { about } = useContext(Data); // Access the about data from the Data context

  return (
    <div className='title-bar'>
      <h1 className='name'>{about.name}</h1>
      <h2 className='title'>{about.title}</h2>
    </div>
  );
}

export default Title;
