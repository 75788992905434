import React from "react";
import { Spinner } from "reactstrap"; // Using reactstrap spinner

const Loading = () => {
  return (
    <div style={styles.loadingContainer}>
      <Spinner style={styles.spinner}  />
      <p style={styles.text}>Loading...</p>
    </div>
  );
};

const styles = {
  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '60vh', // Full page height for centering
  },
  spinner: {
    width: '3rem',
    height: '3rem',
  },
  text: {
    marginTop: '1rem',
    fontSize: '1.2rem',
    color: '#cecece',
  },
};

export default Loading;
