import React, { useContext } from "react";
import { Row, Col } from "reactstrap";
import { SECTIONS } from '../constants';
import FooterLink from './FooterLink';
import { Data } from '../Data'; 

// Function to convert Google Drive view link to download link
const convertGoogleDriveLinkToDownload = (link) => {
  const googleDriveBaseUrl = "https://drive.google.com";
  if (link.startsWith(`${googleDriveBaseUrl}/file/d/`)) {
    const fileId = link.split("/d/")[1].split("/")[0];
    return `${googleDriveBaseUrl}/uc?export=download&id=${fileId}`;
  } else if (link.includes("/view?usp=sharing")) {
    return link.replace("/view?usp=sharing", "/uc?export=download");
  }
  return link;
};

function Footer() {
  const { about, links } = useContext(Data);

  // Map links collection to dynamically generate FooterLink components for social links
  const socialLinks = links
    .filter(link => link.isOn) // Only include active links
    .map(({ name, link }) => (
      <FooterLink key={name} link={link} label={name} isExternal={true} />
    ));

  // Dynamically generate quick links from SECTIONS
  const quickLinks = Object.entries(SECTIONS).map(([key, value]) => {
    // If key is "resume", convert resume link to download from the about data
    let link = `/${key}`;
    let isExternal = false;

    if (key === "resume" && about.resumeLink) {
      link = convertGoogleDriveLinkToDownload(about.resumeLink);
      isExternal = true;
    }

    return (
      <FooterLink key={key} link={link} label={value} isExternal={isExternal} />
    );
  });

  return (
    <div className="footer-container">
      <hr />
      <br />
      <Row xs="1" sm="2" md="4">
        <Col className="footer-col">
          <h4>{about.name || 'Your Name'}</h4>
          <p>{about.address || 'Your Address'}</p>
        </Col>
        <Col className="footer-col">
          <h4>Quick Links</h4>
          <ul>
            {quickLinks}
            <FooterLink link="/legal" label="Privacy Policy" />
          </ul>
        </Col>
        <Col className="footer-col">
          <h4>Social Links</h4>
          <ul>
            {socialLinks}
          </ul>
        </Col>
        <Col className="footer-col">
          <h4>Contact</h4>
          <p>{about.email || 'Your Email'}</p>
          <p>{about.phone || 'Your Phone'}</p>
        </Col>
      </Row>
    </div>
  );
}

export default Footer;
