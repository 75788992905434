import React from "react";
import { Row, Col, Button } from "reactstrap";

// Helper function to format dates as "Month Year"
const formatDate = (dateString) => {
  if (!dateString) return ""; // Handle empty date strings
  const date = new Date(dateString);
  const options = { year: "numeric", month: "long" };
  return date.toLocaleDateString("en-US", options); // e.g., "October 2024"
};

function Card({
  title,
  roles,
  company,
  startDate,
  endDate,
  brief,
  logoUrl,
  tools,
  expertises,
  onClick,
}) {
  return (
    <div className="portfolio-card">
      <Row>
        <h2 className="portfolio-title" onClick={onClick} style={{ cursor: "pointer" }}>
          {title}
        </h2>
      </Row>
      <br />
      <Row xs="1" sm="2">
        <Col sm="12" md="6">
          {/* Conditional rendering for roles */}
          {roles && roles.length > 0 && <p className="role">{roles.join(", ")}</p>}
          {company && <p className="company">{company}</p>}
          
          {/* Display formatted dates */}
          {startDate && <p className="date">{formatDate(startDate)}</p>}
          {endDate && <p className="date">{formatDate(endDate)}</p>}

          <br />
          {brief && <p className="description">{brief}</p>}
          <br />
          {tools && tools.length > 0 && (
            <p className="tools">
              <strong>Used Tools:</strong> {tools.join(", ")}
            </p>
          )}
          {expertises && expertises.length > 0 && (
            <p className="skills">
              <strong>Expertises:</strong> {expertises.join(", ")}
            </p>
          )}
          <Button onClick={onClick}>See Details</Button>
        </Col>
        <Col sm="12" md="6" className="thumbnail-container">
          {logoUrl && (
            <div
              className="thumbnail portfolio-card-image"
              style={{
                backgroundImage: `url(${logoUrl})`,
                cursor: "pointer",
              }}
              aria-label={`${title} thumbnail`}
              onClick={onClick}
            ></div>
          )}
        </Col>
      </Row>
      <br />
      <hr />
    </div>
  );
}

export default Card;
