import React, { useContext } from "react";
import Card from "../components/Card";
import { SECTIONS } from '../constants';
import { useNavigate } from "react-router-dom"; 
import { Helmet } from "react-helmet";
import { Data } from "../Data";
import Loading from "./Loading";

function Portfolio() {
  const navigate = useNavigate();
  const { projects, about, loading } = useContext(Data); // Access projects, about, and loading state

  if (loading) {
    return <div><Loading /></div>;
  }

  // Sort projects based on priority (higher priority comes first)
  const sortedProjects = [...projects].sort((a, b) => b.priority - a.priority);

  return (
    <div>
      <Helmet>
        <title>{`${about.name || 'Your Name'} | ${about.title || 'Portfolio'} - Portfolio`}</title>
        <meta name="description" content={`Learn more about ${about.name || 'Your Name'} by their best work.`} />
        <meta property="og:title" content={`${about.name || 'Your Name'} | ${about.title || 'Portfolio'} - Portfolio`} />
        <meta property="og:description" content={about.description || 'Portfolio Description'} />
        <meta property="og:url" content={`https://${about.domain || 'example.com'}/${SECTIONS.portfolio}`} />
        <meta property="og:type" content="website" />
        {/* Google Analytics tracking for this specific page */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-XXXXXXX-X"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'UA-XXXXXXX-X');
          `}
        </script>
      </Helmet>

      {/* Map through the sorted projects */}
      {sortedProjects.map((project, index) => (
        <Card
          key={index}
          title={project.name} 
          roles={project.roles ? project.roles : []} 
          company={project.client && project.client.length > 0 ? project.client.join(', ') : 'N/A'}
          startDate={project.startDate} 
          endDate={project.endDate}     
          brief={project.brief || 'No brief available'}
          logoUrl={project.LogoUrl && project.LogoUrl[0]} 
          tools={project.tools || []}
          expertises={project.expertises || []}
          // Navigate to the project details route based on the index
          onClick={() => navigate(`/projects/${index}`)}
        />
      ))}
    </div>
  );
}

export default Portfolio;
