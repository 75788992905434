import React, { useContext } from "react";
import { Nav, NavItem } from "reactstrap";
import { useLocation, Link } from "react-router-dom"; // Import Link from react-router-dom
import { SECTIONS } from "../constants";
import { Data } from "../Data"; // Import Data context

// Function to convert Google Drive view link to download link
const convertGoogleDriveLinkToDownload = (link) => {
  const googleDriveBaseUrl = "https://drive.google.com";
  if (link.startsWith(`${googleDriveBaseUrl}/file/d/`)) {
    // Extract the file ID from the URL
    const fileId = link.split("/d/")[1].split("/")[0];
    return `${googleDriveBaseUrl}/uc?export=download&id=${fileId}`;
  } else if (link.includes("/view?usp=sharing")) {
    return link.replace("/view?usp=sharing", "/uc?export=download");
  }
  // If it's not a Google Drive link, return it as is
  return link;
};

function Navbar() {
  const location = useLocation();
  const { about } = useContext(Data); // Access the about data from the Data context

  const navLinks = Object.entries(SECTIONS).map(([key, value]) => {
    // If key is "resume", use the converted resumeLink from the about data
    let path = `/${key.toLowerCase()}`;
    if (key === "resume" && about.resumeLink) {
      path = convertGoogleDriveLinkToDownload(about.resumeLink);
    }
    const isExternalLink = key === "resume"; // For external resume links

    return (
      <NavItem key={key} className="nav-item">
        {/* Use Link for internal navigation and anchor tag for external resume link */}
        {isExternalLink ? (
          <a
            href={path}
            target="_blank"
            rel="noopener noreferrer"
            className="nav-link"
          >
            {value.toUpperCase()}
          </a>
        ) : (
          <Link
            to={path}
            className={location.pathname === path ? "nav-link active" : "nav-link"}
          >
            {value.toUpperCase()}
          </Link>
        )}
      </NavItem>
    );
  });

  return (
    <div className="navbar-links">
      <Nav justified className="nav-bar">
        {navLinks}
      </Nav>
    </div>
  );
}

export default Navbar;
