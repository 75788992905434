import React from "react";

// Helper function to format dates as "Month Year"
const formatDate = (dateString) => {
  if (!dateString) return ""; // Handle empty date strings

  const date = new Date(dateString);
  const options = { year: "numeric", month: "long" };
  return date.toLocaleDateString("en-US", options); // e.g., "October 2024"
};

function ExperienceCard({
  role,
  company,
  startDate,
  endDate,
  location,
  description,
  type,
}) {
  // Check if description is non-empty and not just <p><br/></p>
  const hasValidDescription =
    description && description.trim() !== "" && description !== "<p><br></p>";

  return (
    <div className="experience-card">
      <h4>{role}</h4>
      <p>{company}</p>
      <p>
        {formatDate(startDate)} - {formatDate(endDate)} | {location} | {type}
      </p>

      {/* Render description if it is valid */}
      {hasValidDescription && (
        <div
          className="experience-description"
          dangerouslySetInnerHTML={{ __html: description }}
        ></div>
      )}
    </div>
  );
}

export default ExperienceCard;
