import React, { useContext } from "react";
import { SECTIONS } from "../constants";
import ExperienceCard from "../components/ExperienceCard";
import EducationCard from "../components/EducationCard";
import { Helmet } from "react-helmet";
import { Data } from "../Data";
import Loading from "./Loading";
import Languages from "../components/Languages";
import Skills from "../components/Skills";
import Certifications from "../components/Certifications";
import Achievements from "../components/Achievements";
import Awards from "../components/Awards";
import Publications from "../components/Publications"; 
import VolunteerWork from "../components/VolunteerWork"; 
import { Row, Col } from "reactstrap";

function About() {
  const {
    about,
    education,
    experience,
    certifications,
    achievements,
    publications, 
    volunteerWork, 
    languages,
    awards,
    skills,
    loading,
  } = useContext(Data);

  if (loading) {
    return <Loading />;
  }

  const hasValidContent = (htmlString) => {
    if (!htmlString) return false; // Check if htmlString is undefined or empty
    const cleanedString = htmlString.replace(/<p><br><\/p>/g, "").trim();
    return cleanedString.length > 0;
  };

  // Sort experiences by start date (most recent first)
  const sortedExperience = experience?.sort((a, b) => {
    const dateA = new Date(a.startDate);
    const dateB = new Date(b.startDate);
    return dateB - dateA; // Sort in descending order of start date
  });

  return (
    <div className="about-container">
      <Helmet>
        <title>{`${about.name || "About"} | About`}</title>
        <meta
          name="description"
          content={`Learn more about ${
            about.name || "this individual"
          } from their Portfolio App.`}
        />
        <meta
          property="og:title"
          content={`${about.name || "About"} | About`}
        />
        <meta
          property="og:description"
          content={`${about.description || ""}`}
        />
        <meta
          property="og:url"
          content={`https://${about.domain || ""}/${SECTIONS.about}`}
        />
        <meta property="og:type" content="website" />
      </Helmet>

      {/* Render about section only if it has valid content */}
      {about.about && hasValidContent(about.about) && (
        <div dangerouslySetInnerHTML={{ __html: about.about }}></div>
      )}
      <hr />

      {sortedExperience?.length > 0 && (
        <>
          <h3>Experience</h3>
          {sortedExperience.map((exp, index) => (
            <ExperienceCard key={index} {...exp} />
          ))}
          <hr />
        </>
      )}

      {education?.length > 0 && (
        <>
          <h3>Education</h3>
          {education.map((edu, index) => (
            <EducationCard key={index} {...edu} />
          ))}
          <hr />
        </>
      )}
      <Row className="languages-skills-container">
        <Col className="languages-skills-container" md="4">
          <Languages languages={languages} />
        </Col>
        <Col className="languages-skills-container" md="8">
          <Skills skills={skills} />
        </Col>
      </Row>

      <Certifications certifications={certifications} />

      {/* Render achievements before publications and volunteer work */}
      <Achievements achievements={achievements} />

      {/* Render publications */}
      <Publications publications={publications} />

      {/* Render volunteer work */}
      <VolunteerWork volunteerWork={volunteerWork} />

      <Awards awards={awards} />

      {/* Only render sections if content is valid */}
      {hasValidContent(about.passion) && (
        <>
          <h3>Passion</h3>
          <div dangerouslySetInnerHTML={{ __html: about.passion }}></div>
        </>
      )}

      {hasValidContent(about.hobbies) && (
        <>
          <h3>Hobbies</h3>
          <div dangerouslySetInnerHTML={{ __html: about.hobbies }}></div>
        </>
      )}
    </div>
  );
}

export default About;