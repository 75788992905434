import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { Data } from "../Data";
import { Row, Col, Button } from "reactstrap";
import ProjectCarousel from "../components/Carousel";
import { Helmet } from "react-helmet";
import { ABOUT } from "../constants";
import Loading from "../pages/Loading";

function ProjectDetails() {
  const { projectIndex } = useParams();
  const { projects, loading } = useContext(Data);

  if (loading) return <Loading />;

  const sortedProjects = [...projects].sort((a, b) => b.priority - a.priority);
  const project = sortedProjects[projectIndex];
  if (!project) return <div>Project not found!</div>;

  // Helper function to format dates as "Month Year"
const formatDate = (dateString) => {
  if (!dateString) return "";
  const date = new Date(dateString);
  const options = { year: "numeric", month: "long" };
  return date.toLocaleDateString("en-US", options);
};

  // Check if URL is a YouTube link
  const isYouTubeUrl = (url) => url.includes("youtube.com") || url.includes("youtu.be");

  // Convert YouTube URL to embed format
  const getYouTubeEmbedUrl = (url) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return match && match[2] ? `https://www.youtube.com/embed/${match[2]}` : url;
  };

  // Combine videos and demo reel, placing YouTube videos first
  const combinedVideos = [
    ...(project.demoReel ? [{ type: 'youtube', url: getYouTubeEmbedUrl(project.demoReel) }] : []),
    ...(project.VideoUrl || []).map((url) => ({
      type: isYouTubeUrl(url) ? 'youtube' : 'video',
      url: isYouTubeUrl(url) ? getYouTubeEmbedUrl(url) : url,
    })),
  ];

  const generateAltText = (index) => `Media ${index + 1} for project ${project.name}`;

  return (
    <div className="project-details">
      <Helmet>
        <title>{`${ABOUT.name} | ${project.name}`}</title>
        <meta name="description" content={`Learn more about ${project.name}. Tools: ${project.tools?.join(", ")}`} />
        <meta property="og:title" content={`${ABOUT.name} | ${project.name}`} />
        <meta property="og:description" content={`${project.description}`} />
        <meta property="og:url" content={`https://${ABOUT.domain}/portfolio/${projectIndex}`} />
        <meta property="og:type" content="website" />
      </Helmet>

      <Row>
        <h2 className="title">{project.name}</h2>
      </Row>

      <Row className="date">
      {project.roles?.length > 0 && <p className="role">{project.roles.join(", ")}</p>}
        {project.client?.length > 0 && <p className="company">{project.client.join(", ")}</p>}
        {project.startDate && <p className="date">{formatDate(project.startDate)}</p>}
        {project.endDate && <p className="date">{formatDate(project.endDate)}</p>}
      </Row>

      {project.logoUrl && project.logoUrl.length > 0 && (
        <Row>
          <Col>
            <img src={project.logoUrl[0]} alt={`${project.name} thumbnail`} className="project-thumbnail" style={{ maxWidth: "200px", maxHeight: "200px" }} />
          </Col>
        </Row>
      )}

      {/* Render video carousel with YouTube video at the first index */}
      {combinedVideos.length > 0 && (
        <Row className="video-section">
          <ProjectCarousel mediaUrls={combinedVideos} generateAltText={generateAltText} />
        </Row>
      )}

      {project.ImageUrl && project.ImageUrl.length > 0 && (
        <Row>
          <ProjectCarousel mediaUrls={project.ImageUrl.map((url) => ({ type: 'image', url }))} generateAltText={generateAltText} />
        </Row>
      )}

      <Row className="tools-and-skills">
        <Col>
          {project.tools && <p className="tools"><strong>Used Tools:</strong> {project.tools.join(", ")}</p>}
        </Col>
        <Col>
          {project.expertises && <p className="skills"><strong>Expertises:</strong> {project.expertises.join(", ")}</p>}
        </Col>
      </Row>

      {project.description && (
        <Row>
          <div className="description" dangerouslySetInnerHTML={{ __html: project.description }}></div>
        </Row>
      )}

      {project.projectLink && (
        <Row className="project-link-row">
          <Col>
            <a href={project.projectLink} target="_blank" rel="noopener noreferrer" className="project-link-button">
              <Button className="button">Visit Project Website</Button>
            </a>
          </Col>
        </Row>
      )}

      {/* Additional project details */}
      <hr />
      <br />

      {project.intentions && (
        <Row>
          <h3>Intentions</h3>
          <div className="description" dangerouslySetInnerHTML={{ __html: project.intentions }}></div>
        </Row>
      )}
      {project.objectives && (
        <Row>
          <h3>Objectives</h3>
          <div className="description" dangerouslySetInnerHTML={{ __html: project.objectives }}></div>
        </Row>
      )}
      {project.process && (
        <Row>
          <h3>Process</h3>
          <div className="description" dangerouslySetInnerHTML={{ __html: project.process }}></div>
        </Row>
      )}
      {project.results && (
        <Row>
          <h3>Results</h3>
          <div className="description" dangerouslySetInnerHTML={{ __html: project.results }}></div>
        </Row>
      )}
      {project.reflections && (
        <Row>
          <h3>Reflections</h3>
          <div className="description" dangerouslySetInnerHTML={{ __html: project.reflections }}></div>
        </Row>
      )}
    </div>
  );
}

export default ProjectDetails;
