// About Information (matches the 'about' collection in the database)
export const ABOUT = {
  name: "Alp Kurt",
  title: "Game Developer",
  about: "<p>about</p>",
  description: "<p>about</p>",
  address: "Berlin, 10999 GER",
  domain: "https://alpkurt.com",
  email: "krtalp@gmail.com",
  phone: "+497777777777",
  hobbies: "<p>Hobby</p>",
  passion: "<p>Passion</p>",
  resumeLink: "https://drive.google.com/file/d/1qd7uIJPxwbJ4et4YO56Id-bzrYMqMr9q/view",
  createdAt: "11 October 2024 at 16:26:01 UTC+3",
  modifiedAt: "17 October 2024 at 20:38:55 UTC+3"
};

// Static content configuration
export const STATIC_CONTENT = {
  projects: false, // Determines whether to use static projects data
  about: false,     // Determines whether to use static about data
  combine: false   // If true, combines static and live data
};

// Education Information (matches the 'education' collection in the database)
export const EDUCATION = [
  {
    degree: "Master's",
    department: "New Media Design",
    startDate: "2023-09-23",
    endDate: "2024-08-26",
    university: "University of Europe",
    location: "Berlin, GER",
    description: "",
    priority: "15",
    status: [],
    isOn: false,
    createdAt: "5 October 2024 at 21:43:43 UTC+3",
    modifiedAt: "5 October 2024 at 21:43:43 UTC+3"
  }
];

// Experience Information (matches the 'experience' collection in the database)
export const EXPERIENCE = [
  {
    role: "Game Developer",
    company: "Lupi Games",
    startDate: "2023-01-01",
    endDate: "Present",
    location: "Remote, Global",
    description: "",
    priority: "1",
    status: [],
    isOn: true,
    createdAt: "11 October 2024 at 17:31:58 UTC+3",
    modifiedAt: "11 October 2024 at 17:31:58 UTC+3"
  }
];

// Languages Information (matches the 'languages' collection in the database)
export const LANGUAGES = [
  {
    name: "English",
    proficiency: "Full Professional",
    priority: "1",
    isOn: true,
    createdAt: "10 October 2024 at 19:23:56 UTC+3",
    modifiedAt: "10 October 2024 at 23:17:56 UTC+3"
  },
  {
    name: "German",
    proficiency: "Professional Working",
    priority: "2",
    isOn: true,
    createdAt: "10 October 2024 at 19:23:56 UTC+3",
    modifiedAt: "10 October 2024 at 23:17:56 UTC+3"
  }
];

// Skills Information (matches the 'skills' collection in the database)
export const SKILLS = [
  {
    skillName: "Game Development",
    experience: "Expert",
    isSoft: false,
    isOn: true,
    createdAt: "12 October 2024 at 14:14:34 UTC+3",
    modifiedAt: "12 October 2024 at 14:14:34 UTC+3"
  },
  {
    skillName: "Team Management",
    experience: "Intermediate",
    isSoft: true,
    isOn: true,
    createdAt: "12 October 2024 at 14:14:34 UTC+3",
    modifiedAt: "12 October 2024 at 14:14:34 UTC+3"
  }
];

// Certifications Information (matches the 'certifications' collection in the database)
export const CERTIFICATIONS = [
  {
    name: "Unity Certified Developer",
    issuingBody: "Unity Technologies",
    certificationURL: "https://example.com",
    issuingBodyURL: "https://unity.com",
    date: "2024-10-09",
    description: "<p>Unity Certification</p>",
    isOn: true,
    createdAt: "10 October 2024 at 18:23:43 UTC+3",
    modifiedAt: "10 October 2024 at 18:25:02 UTC+3"
  }
];

// Awards Information (matches the 'awards' collection in the database)
export const AWARDS = [
  {
    name: "Best Game Design",
    issuingBody: "Berlin Indie Game Fest",
    issuingBodyURL: "https://indiefest.com",
    date: "2022",
    description: "<p>Award for the best game design</p>",
    isOn: true,
    createdAt: "10 October 2024 at 23:12:02 UTC+3",
    modifiedAt: "10 October 2024 at 23:17:41 UTC+3"
  }
];

// Awards Information (matches the 'awards' collection in the database)
export const ACHIEVEMENTS = [
  {
    name: "Best Game Design",
    issuingBody: "Berlin Indie Game Fest",
    issuingBodyURL: "https://indiefest.com",
    date: "2022",
    description: "<p>Award for the best game design</p>",
    isOn: true,
    createdAt: "10 October 2024 at 23:12:02 UTC+3",
    modifiedAt: "10 October 2024 at 23:17:41 UTC+3"
  }
];

// Publications
export const PUBLICATIONS = [
  {
    title: "Combat Design in MOBAs",
    authors: "Alp Kurt",
    type: "Paper",
    publishingBody: "University of Europe for Applied Sciences",
    publicationURL: "https://example.com",
    date: "2024-08-01",
    doi: "doi./3124124",
    abstract: "<p>Exploring combat mechanics and strategies in modern Multiplayer Online Battle Arenas (MOBAs).</p>",
    isOn: true,
    createdAt: "2024-08-01T06:00:00Z",
    modifiedAt: "2024-11-10T04:05:26Z",
  },
  {
    title: "Game Narratives in Interactive Fiction",
    authors: "Jane Doe",
    type: "Journal Article",
    publishingBody: "Interactive Media Journal",
    publicationURL: "https://journal.example.com/article123",
    date: "2023-05-15",
    doi: "doi./5678910",
    abstract: "<p>An analysis of narrative structures and player engagement in interactive fiction games.</p>",
    isOn: true,
    createdAt: "2023-05-01T06:00:00Z",
    modifiedAt: "2023-05-10T09:00:00Z",
  }
];

// Volunteer Work
export const VOLUNTEER_WORK = [
  {
    role: "Front Desk, Registration",
    organization: "Games Ground",
    organizationURL: "https://www.gamesground.de/",
    date: "2024-11-11",
    location: "Berlin",
    description: "<p>Assisted in managing event registrations and providing visitor guidance at Games Ground.</p>",
    isOn: true,
    createdAt: "2024-11-11T06:54:27Z",
    modifiedAt: "2024-11-11T06:58:18Z",
  },
  {
    role: "Workshop Assistant",
    organization: "Tech for Youth",
    organizationURL: "https://techforyouth.org",
    date: "2023-09-15",
    location: "Hamburg",
    description: "<p>Volunteered as an assistant during tech workshops, helping participants with coding exercises.</p>",
    isOn: true,
    createdAt: "2023-09-01T07:30:00Z",
    modifiedAt: "2023-09-15T08:45:00Z",
  }
];


// Contact Information
export const CONTACT_INFO = {
  email: "krtalp@gmail.com",
  phone: "+4917668013463"
};

// External Links (e.g., social media)
export const LINKS = [
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/alp-kurt/",
    isOn: true,
    createdAt: "27 October 2024 at 21:37:50 UTC+3",
    modifiedAt: "27 October 2024 at 21:39:14 UTC+3",
  },
  {
    name: "GitHub",
    link: "https://github.com/pabron7",
    isOn: true,
    createdAt: "27 October 2024 at 21:37:50 UTC+3",
    modifiedAt: "27 October 2024 at 21:39:14 UTC+3",
  },
  {
    name: "Instagram",
    link: "https://instagram.com/papthings",
    isOn: true,
    createdAt: "27 October 2024 at 21:37:50 UTC+3",
    modifiedAt: "27 October 2024 at 21:39:14 UTC+3",
  }
];

// Navigation Sections
export const SECTIONS = {
  portfolio: "Portfolio",
  about: "About",
  resume: "Get My Resume"
};

// Corrected Projects Information
export const PROJECTS = [
  {
    title: "Project 1",
    brief: "This is a brief for Project 1. Lorem ipsum dolor sit amet.",
    description: "This is a detailed description of Project 1.",
    startDate: "Jan 2021",
    endDate: "Jan 2021",
    company: "Company ABC",
    role: "Developer",
    intentions: "Intentions of Project 1",
    objectives: "Objectives of Project 1",
    process: "Process followed in Project 1",
    results: "Results of Project 1",
    reflections: "Reflections on Project 1",
    ImageUrl: [
      "https://picsum.photos/id/123/1200/400",
      "https://picsum.photos/id/456/1200/400",
      "https://picsum.photos/id/678/1200/400"
    ],
    VideoUrl: ["https://youtu.be/mqJcIxKklws"],
    LogoUrl: [
      "https://picsum.photos/id/123/200/200"
    ], // Corrected to match array structure
    projectLink: "https://example.com",
    tools: ["Unity", "Photoshop", "Miro"],
    skills: ["Game Development", "Game Direction", "Project Management"],
    client: ["RASITGR Games"],
    isOn: true,
    priority: "1"
  },
];

// Firestore Collection Settings
export const FIREBASE_COLLECTION = {
  collection: "gameDevelopment"
};
