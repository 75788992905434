import React from "react";

function Languages({ languages }) {
  const sortedLanguages = [...languages].sort((a, b) => b.priority - a.priority);

  return sortedLanguages.length > 0 ? (
    <>
      <h3>Languages</h3>
      <ul className="language-list">
        {sortedLanguages.map((lang, index) => (
          <li key={index} className="language-item">
            <span className="language-name">{lang.name}</span> - 
            <span className="language-proficiency"> {lang.proficiency}</span>
          </li>
        ))}
      </ul>
    </>
  ) : null;
}

export default Languages;
