import React from "react";

function Awards({ awards }) {
  return awards?.length > 0 ? (
    <>
      <h3>Awards</h3>
      <ul>
        {awards.map((award, index) => (
          <li key={index}>
            <strong>{award.name}</strong> at{" "}
            {award.issuingBodyURL ? (
              <a href={award.issuingBodyURL} target="_blank" rel="noopener noreferrer">
                {award.issuingBody}
              </a>
            ) : (
              award.issuingBody
            )},{" "}
            {new Date(award.date).toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
            })}
            {award.description && (
              <div className="award-description" dangerouslySetInnerHTML={{ __html: award.description }}></div>
            )}
          </li>
        ))}
      </ul>
    </>
  ) : null;
}

export default Awards;
