import React from "react";

function Certifications({ certifications }) {
  return certifications?.length > 0 ? (
    <>
      <h3>Certifications</h3>
      <ul>
        {certifications.map((cert, index) => (
          <li key={index}>
            <strong>
              {cert.certificationURL ? (
                <a href={cert.certificationURL} target="_blank" rel="noopener noreferrer">
                  {cert.name}
                </a>
              ) : (
                cert.name
              )}
            </strong>{" "}
            issued by{" "}
            {cert.issuingBodyURL ? (
              <a href={cert.issuingBodyURL} target="_blank" rel="noopener noreferrer">
                {cert.issuingBody}
              </a>
            ) : (
              cert.issuingBody
            )}{" "}
            on{" "}
            {new Date(cert.date).toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
            })}
          </li>
        ))}
      </ul>
    </>
  ) : null;
}

export default Certifications;
