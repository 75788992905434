import React from "react";
import { Link } from "react-router-dom"; // Import Link for internal navigation

function FooterLink({ link, label, isExternal = false }) {
  return (
    <li>
      {isExternal ? (
        <a href={link} target="_blank" rel="noopener noreferrer" className="footer-link">
          {label}
        </a>
      ) : (
        <Link to={link} className="footer-link">
          {label}
        </Link>
      )}
    </li>
  );
}

export default FooterLink;
