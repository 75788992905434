import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { Data } from "../Data"; // Import Data context for dynamic data

function PrivacyPolicy() {
  const { about } = useContext(Data); // Access the about data from the Data context

  return (
    <div className="legal-container">
      <Helmet>
        <title>{`${about.name || "Your Name"} | ${about.title || "Your Title"} Portfolio App Legal Page`}</title>
        <meta
          name="description"
          content="Learn more about their Privacy Policy."
        />
        <meta
          property="og:title"
          content={`${about.name || "Your Name"} | ${about.title || "Your Title"} - Legal Page`}
        />
        <meta
          property="og:description"
          content={about.description || "Privacy Policy description"}
        />
        <meta
          property="og:url"
          content={`https://${about.domain || "yourdomain.com"}/legal`}
        />
        <meta property="og:type" content="website" />
        {/* Google Analytics tracking for this specific page */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-XXXXXXX-X"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'UA-XXXXXXX-X');
          `}
        </script>
      </Helmet>

      <h1>Privacy Policy</h1>

      <p>Last updated: 02.10.2024</p>

      <p>
        This Privacy Policy describes how your personal information is
        collected, used, and shared when you visit or make use of the website,
        including my use of Google Analytics.
      </p>
      <br />
      <hr />
      <h2>Personal Information I Collect</h2>
      <p>
        When you visit this website, I automatically collect certain information
        about your device, including information about your web browser, IP
        address, time zone, and some of the cookies installed on your device.
        Additionally, as you browse the site, I collect information about the
        individual web pages or products you view, what websites or search terms
        referred you to the site, and information about how you interact with
        the site.
      </p>
      <br />
      <hr />
      <h2>Google Analytics</h2>
      <p>
        This website uses Google Analytics, a web analytics service provided by
        Google, Inc. ("Google"). Google Analytics uses cookies, which are text
        files placed on your computer, to help the website analyze how users use
        the site.
      </p>
      <p>
        The information generated by the cookie about your use of the website
        (including your IP address) will be transmitted to and stored by Google
        on servers in the United States. Google will use this information for
        the purpose of evaluating your use of the website, compiling reports on
        website activity for website operators, and providing other services
        relating to website activity and internet usage.
      </p>
      <p>
        You may refuse the use of cookies by selecting the appropriate settings
        on your browser; however, please note that if you do this, you may not
        be able to use the full functionality of this website. You can also
        opt-out of Google Analytics tracking by installing the Google Analytics
        Opt-out Browser Add-on.
      </p>
      <br />
      <hr />

      <h2>Data Storage in Your Browser</h2>
      <p>
        I use sessionStorage and localStorage in your browser to temporarily
        store non-personal data such as project details and portfolio
        information.
      </p>
      <p>
        This data is stored to improve website performance by reducing the need
        to repeatedly fetch data from my servers. The information stored in
        sessionStorage is cleared when you close your browser, while information
        in localStorage may persist until you manually clear it.
      </p>
      <p>
        You can control or delete this locally stored data at any time through
        your browser settings. I do not store any personal or sensitive data in
        your browser’s storage, and this data is only used to enhance your
        experience on this website.
      </p>

      <br />
      <hr />

      <h2>Opting Out</h2>
      <p>
        If you do not want your data to be collected by Google Analytics, you
        can opt out by installing the Google Analytics Opt-out Browser Add-on:{" "}
        <a href="https://tools.google.com/dlpage/gaoptout">Google Opt-out</a>.
      </p>
      <br />
      <hr />

      <h2>Your Rights</h2>
      <p>
        Depending on where you live, you may have rights under data protection
        laws (such as GDPR or CCPA) to request access to, correct, or delete
        your personal information. Please contact me if you have any concerns
        regarding your data.
      </p>
      <br />
      <hr />

      <h2>Changes to This Policy</h2>
      <p>
        I may update this Privacy Policy from time to time in order to reflect,
        for example, changes to these practices or for other operational, legal,
        or regulatory reasons.
      </p>
      <br />
      <hr />
      <h2>Contact Me</h2>
      <p>
        If you have questions or concerns about this privacy policy, please
        contact me at {about.email || "your-email@example.com"}.
      </p>
    </div>
  );
}

export default PrivacyPolicy;
