import './styles.scss';
import { BrowserRouter as Router, Route, Routes, Navigate, } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';
import ScrollToTop from './components/ScrollToTop';

import Title from './components/Title';
import Navbar from './components/Navbar';
import Footer from './components/Footer';

import Portfolio from './pages/Portfolio';
import About from './pages/About';
import Projects from './pages/Projects';
import PrivacyPolicy from './pages/PrivacyPolicy';

function App() {
  return (
    <Router >
    <ScrollToTop /> 
    <Title />
    <Navbar />
    <br />
    <hr />
    <Routes>
        <Route path="/" element={<Navigate to="/portfolio" />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/about" element={<About />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/legal" element ={<PrivacyPolicy />} />

          <Route path="/projects/:projectIndex" element={<Projects />} />
          <Route path="/*" element={<Portfolio />} />
        </Routes>
        <br />
        <hr />
    <Footer />
    
    <CookieConsent
        location="bottom"
        buttonText="I Accept"
        cookieName="yourWebsiteCookieConsent"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        This website uses cookies to enhance the user experience. By clicking "I Accept," you consent to the use of cookies in accordance with my{" "}
        <a href="/legal" style={{ color: "#fff" }}>Privacy Policy</a>.
      </CookieConsent>
    </Router>
  );
}

export default App;
