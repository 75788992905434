import React from "react";

function Publications({ publications }) {
  return publications?.length > 0 ? (
    <>
      <h3>Publications</h3>
      <ul>
        {publications.map((publication, index) => (
          <li key={index}>
            <strong>{publication.title}</strong> by {publication.authors},{" "}
            {publication.date && new Date(publication.date).toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
            })}
            {publication.publishingBody && (
              <> at <em>{publication.publishingBody}</em></>
            )}
            {publication.publicationURL && (
              <>
                {" "}
                - <a href={publication.publicationURL} target="_blank" rel="noopener noreferrer">
                  View Publication
                </a>
              </>
            )}
            {publication.abstract && (
              <div className="publication-abstract" dangerouslySetInnerHTML={{ __html: publication.abstract }}></div>
            )}
          </li>
        ))}
      </ul>
    </>
  ) : null;
}

export default Publications;
